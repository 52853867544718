import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';


import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiService } from './common/api.service';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { AutofocusDirective } from './autofocus.directive';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { HttpResponseInterceptor } from './common/http.interceptor';
import { LockedComponent } from './locked/locked.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotpasswordComponent,
    AutofocusDirective,
    ChangepasswordComponent,
    LockedComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule
  ],
  providers: [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpResponseInterceptor,
        multi: true
    },
    ApiService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
