import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { LockedComponent } from './locked/locked.component';

export const appRoutes: Routes = [
  { path: 'login', component: LoginComponent},
  { path: 'forgot-password', component: ForgotpasswordComponent},
  { path: 'change-password', component: ChangepasswordComponent},
  { path: 'account-locked', component: LockedComponent},
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  // { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes
      // { enableTracing: true } // <-- debugging purposes only
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}
